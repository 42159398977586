/* eslint-disable no-new */
import Vue from 'vue'
import App from './App.vue'
import i18n from './vue/i18n/index'
import router from './vue/router/index'
import store from './vue/store/index'
import './vue/resource/index'
import './vue/components'
import './vue/filters'
import './vue/mixins'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import Verte from 'verte';
import 'verte/dist/verte.css';
import UUID from "vue-uuid";
Vue.component('verte', Verte);
Vue.use(UUID);
import ActionCableVue from 'actioncable-vue';
import VeeValidate from 'vee-validate'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.use(ActionCableVue, {
  debug: true, // Configura esto a "false" en producción para deshabilitar mensajes de depuración
  debugLevel: 'all', // Nivel de mensajes de depuración: 'all', 'trace', 'debug', 'info', 'warn', 'error', 'fatal', 'off'
  // connectionUrl: 'wss://solutions.fusepong.com/cable', // Reemplaza esto con la URL de tu servidor de Action Cable
  // connectionUrl: 'wss://newsolutions.fusepong.com/cable', // Reemplaza esto con la URL de tu servidor de Action Cable
  connectionUrl: 'wss://staging.fusepong.com/cable', // Reemplaza esto con la URL de tu servidor de Action Cable
  // connectionUrl: 'ws://192.168.0.33:4000/cable', // Reemplaza esto con la URL de tu servidor de Action Cable
});

const VueValidationEs = require('vee-validate/dist/locale/es');
Vue.use(VeeValidate, {
    locale: 'es',
    dictionary: {
      es: VueValidationEs
    }
});
Vue.use(DatePicker)

// Moment translate
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment
})

// register component globally

new Vue({
  el: '#app',
  router: router,
  render: h => h(App),
  i18n: i18n,
  store: store,
  vuetify
})

Vue.http.interceptor.before = (request, next) => {
  console.log(request);
  // cl router.app.encrypt({attributes: {user_id: 1,from: "16-05-2019", until: "17-05-2019",init_hour:"08:00",finish_hour:"16:00",available_locations:[1,5],offset_time:new Date().getTimezoneOffset()},invited_users_attributes:[{email:"alguien51@yopmail.com"}]}).toString()
  router.app.updateLoader(true)
  next((response) => {
    console.log(response);
    router.app.updateLoader(false)
    if(response.status == 401){
      router.app.$router.push({name: 'root'})
      router.app.updateIsLogin(false)
      if(response.body.meta != undefined && response.body.meta != null){
        if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
          router.app.checkToken(response.body.meta.authentication_token)
        }
      }
      alert("Tu sesión ha vencido, vuelve a ingresar para continuar")
    }
  })
}
